import { useState } from 'react';
import { TextField } from '@mui/material';

function InputDisplay() {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      label="Type something..."
      variant="outlined"
      value={inputValue}
      onChange={handleInputChange}
      fullWidth
      multiline
      rows={4}
    />
  );
}

export default InputDisplay;