import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';

//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>
//);

//ReactDOM.render(
//  <BrowserRouter>
//    <App/>
//  </BrowserRouter>,
//  document.getElementById('root')
//)


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

//ReactDOM.render(
//  <React.StrictMode>
//    <BrowserRouter>
//      <Routes>
//        <Route path="/" element={ <App /> }>
//        </Route>
//      </Routes>
//    </BrowserRouter>
//  </React.StrictMode>,
//  document.getElementById('root')
//);