import './App.css';

import React, { useRef, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import { makeStyles } from '@mui/styles';

import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import InputDisplay from './InputDisplay'

import { ThemeProvider, createTheme } from '@mui/material/styles';

import {Buffer} from 'buffer';

import logo from './img/sinchLogo.svg';



const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const THEME = createTheme({
  typography: {
    "font-family": 'Montserrat',
    "fontSize": 12,
    "fontWeightLight": 400,
    "fontWeightRegular": 500,
    "fontWeightMedium": 700,
  },

  palette: {
    anger: createColor('#F40B27'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3'),
    sinchyellow: createColor('#FFBE3C'),
  },
});

function successfulLogin(navigate) {
  // call some API or do some logic here...
  navigate('/mainmenu'); // navigate to '/my-route'
}



function App() {

  const navigate = useNavigate();

  const [verifyTypeDialog, setVerifyTypeDialog] = useState(false);
  const [emailOrMobileDialog, setEmailOrMobileDialog] = useState(false);
  const [verifyPinDialog, setVerifyPinDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [loginData, setLoginData] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginResponse, setLoginResponse] = useState("");
  const [globalPin, setGlobalPin] = useState("");
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const [value, setValue] = useState("sms");
  const [emailOrMobile, setEmailOrMobile] = useState("");

  const [randomNumber, setRandomNumber] = useState(null);

  const [text, setText] = useState('');
  const [response, setResponse] = useState('');

  const userNameRef = useRef('');
  const userPasswordRef = useRef('');
  const emailOrMobileRef = useRef('');
  const verifyPinCodeRef = useRef('');
  const chatInputRef = useRef('');

  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);



  async function handleChatClick() {
    setIsLoading(true);
    setError(null);

    console.log(chatInputRef.current.value)
    const chatInput = chatInputRef.current.value.toString();
    console.log(chatInput);

    const response = await fetch(`https://msgg.me/api/openai/${chatInput}`);
    const data = await response.json();
    console.log(data);
    setOutputValue(data);

    //fetch(`https://msgg.me/api/openai/${chatInput}`)
    //const data = await response.json();
    //console.log(data);
    //  .then(response => response.json())
    //  .then(data => console.log(data))
    //  .catch(error => console.error(error));

  };




  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  function generateRandomNumber() {
    const min = 100000; // minimum value (inclusive)
    const max = 999999; // maximum value (inclusive)
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomNumber(number);
    return(number);
  }

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const closeVerifyTypeDialog = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setVerifyTypeDialog(false);
    console.log(verifyPinCodeRef);
  };

  const closeEmailOrMobileDialog = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setEmailOrMobileDialog(false);
  };

  const closeAlertDialog = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setAlertDialog(false);
  };

  function handleChooseMethod() {
    console.log(value)
    if (value == "email") {
      console.log("Ask for email")
      setEmailOrMobile("Email Address")
      setEmailOrMobileDialog(true);
    } else {
      console.log("Ask for number")
      setEmailOrMobile("Mobile Number")
      setEmailOrMobileDialog(true);
    }
  }

  function handleSubmit() {}

  async function HandleConnectClick() {

    const username = userNameRef.current.value.toString();
    const password = userPasswordRef.current.value.toString();
    console.log(username);
    console.log(password);

    const response = await fetch(`https://msgg.me/api/login/${username}/${password}`)
    const data = await response.json();

    console.log(response);
    console.log(data);

    console.log(response['status'])
    var responseCode = response['status'].toString()

    if (responseCode == "200") {
      setVerifyTypeDialog(true);
      setIsUserAuthenticated(true);

      navigate("/mainenu");
      

    } else {
      setAlertSeverity("error");
      setAlertContent("Username or Password is wrong!!!");
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
        }, 3000);
    }
  }


  async function handleDialOut() {
    console.log("HandleDialOut")
    console.log(value)
    console.log(emailOrMobile.toString())
    if (value == "dtmf"){
      console.log("DTMF Call Out");

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/voicedtmf/${msisdn}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setEmailOrMobileDialog(false);
      setAlertSeverity("info");
      setAlertContent("You will receive a DTMF Voice Call at " + msisdn);
      setAlertDialog(true);
      console.log("HUH!?")
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true);
        }, 10000);
    
    } else if (value == "sms") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/sms/${msisdn}/${pincode}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setEmailOrMobileDialog(false);
      setAlertSeverity("info");
      setAlertContent("You will receive the pin code " + pincode + " at " + msisdn);
      setAlertDialog(true);
      console.log("HUH!?")
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true);
        }, 10000);


    } else if (value == "whatsapp") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/whatsapp/${msisdn}/${pincode}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setAlertSeverity("info");
      setAlertContent("You will receive the pin code " + pincode + " at " + msisdn);
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true); 
        }, 10000);   

    } else if (value == "viber") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/viber/${msisdn}/${pincode}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setAlertSeverity("info");
      setAlertContent("You will receive the pin code " + pincode + " at " + msisdn);
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true); 
        }, 10000);

    } else if (value == "email") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const emailAdd = emailOrMobileRef.current.value.toString();
      console.log(emailAdd);
      const encodedEmailAddress = encodeURIComponent(emailAdd);
      console.log(encodedEmailAddress)

      fetch(`https://msgg.me/api/email/${encodedEmailAddress}/${pincode}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setAlertSeverity("info");
      setAlertContent("You will receive the pin code " + pincode + " at " + emailAdd);
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true); 
        }, 10000);

    } else if (value == "tts") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/tts/${msisdn}/${pincode}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setAlertSeverity("info");
      setAlertContent("You will receive a call at " + msisdn);
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true);
        }, 10000);

    } else if (value == "flashcall") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/flashcall/${msisdn}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setAlertSeverity("info");
      setAlertContent("You will receive a flash call at " + msisdn);
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true);
        }, 10000);

    } else if (value == "flashcall") {
      const tempPin = generateRandomNumber().toString();

      const pincode = tempPin.toString();
      setGlobalPin(pincode)
      console.log(pincode);
      console.log(globalPin);

      console.log(emailOrMobileRef.current.value)
      const msisdn = emailOrMobileRef.current.value.toString();
      console.log(msisdn);

      fetch(`https://msgg.me/api/dtmf/${msisdn}`)
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error(error));

      setAlertSeverity("info");
      setAlertContent("You will receive a DTMF call at " + msisdn);
      setAlertDialog(true);
      setTimeout(() => { 
          setAlertDialog(false);
          setVerifyPinDialog(true);
        }, 10000);
    } else {}

  }


  const LoginPage = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Paper elevation={10} style={{ padding: 20, minWidth: 400 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <TextField label="Username" variant="outlined" margin="normal" fullWidth inputRef={userNameRef}/>
            <TextField label="Password" type="password" variant="outlined" margin="normal" fullWidth inputRef={userPasswordRef}/>
            <Button variant="contained" color="sinchyellow" fullWidth onClick={HandleConnectClick} >  
              Let's Start Verifying!
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  };

  const MainMenu = () => {
    return (
      isUserAuthenticated ?

      <React.Fragment>
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Paper elevation={10} style={{ padding: 20, minWidth: 400 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <FormControl>
              
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <FormControlLabel value="sms" control={<Radio />} label="SMS" />
                <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" />
                <FormControlLabel value="viber" control={<Radio />} label="Viber" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="flashcall" control={<Radio />} label="Flash Call" />
                <FormControlLabel value="tts" control={<Radio />} label="Text To Speech" />
                <FormControlLabel value="dtmf" control={<Radio />} label="Voice Call DTMF" />
              </RadioGroup>
            </FormControl>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Grid>
              <Button variant="contained" color="sinchyellow"  onClick={closeVerifyTypeDialog} >Cancel</Button>
              <a>  </a>
              <Button variant="contained" color="sinchyellow"  onClick={handleChooseMethod} >Verify !</Button>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box display="flex" justifyContent="center">
        <Paper elevation={10} style={{ padding: 20, maxWidth: 400 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {outputValue && (
              <Typography variant="body1" component="div" margin="normal" style={{ minWidth: 400, maxWidth: 600 }}>
                {outputValue}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>



      <Box display="flex" justifyContent="center">
        <Paper elevation={10} style={{ padding: 20, minWidth: 400, maxWidth: 600 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
           <TextField id="outlined-basic" label="Ask something..." variant="outlined" fullWidth multiline row={2} inputRef={chatInputRef} style={{ marginBottom: "10px" }}/>

           <Button variant="contained" color="sinchyellow"  onClick={handleChatClick} >Tell me!</Button>
          </Box>
        </Paper>
      </Box>



      </React.Fragment>

      :

      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Paper elevation={10} style={{ padding: 20, minWidth: 400 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <TextField label="Username" variant="outlined" margin="normal" fullWidth inputRef={userNameRef}/>
            <TextField label="Password" type="password" variant="outlined" margin="normal" fullWidth inputRef={userPasswordRef}/>
            <Button variant="contained" color="sinchyellow" fullWidth onClick={HandleConnectClick} > 
              Let's Start Verifying!
            </Button>
          </Box>
        </Paper>
      </Box>
    )
  }



  return (
    <ThemeProvider theme={THEME}>


      <Dialog open={emailOrMobileDialog} onClose={closeEmailOrMobileDialog}>
        <DialogTitle> {emailOrMobile} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide your {emailOrMobile}
          </DialogContentText>
          <TextField autoFocus margin="dense" id="emailOrMobile" fullWidth variant="filled"
            inputRef={emailOrMobileRef}>
          </TextField>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="sinchyellow" style={{ width: '120px' }} onClick={closeEmailOrMobileDialog} >Cancel</Button>
          <Button variant="contained" color="sinchyellow" style={{ width: '120px' }} onClick={handleDialOut} >Verify Me!</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={alertDialog} onClose={closeAlertDialog}>
        <Alert severity={alertSeverity}>
          {alertContent}
        </Alert>
      </Dialog>

      
      <Box sx={{ flexGrow: 1, whiteSpace: 'normal' }} >
        <AppBar position="static" style={{ background: '#ffffff', width: '100%' }} >
          <Toolbar>
            <img src={logo} alt="logo" />
          </Toolbar>
        </AppBar>
      </Box>
      
      <div>
        <Routes>
          <Route exact path="/" element={<LoginPage />}/>
          <Route exact path="/about" element={<MainMenu />}/>
          <Route exact path="/mainenu" element={<MainMenu />}/>
        </Routes>
      </div>

    </ThemeProvider>
  );
}

export default App;